/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 03.12.2018, 15:20:46
    
*/

a{
	&.slider_arrow{
		/*background-color: $dark;*/
		svg{
			color: $text_light;
		}
	}
	&.footer_link{
		@extend %footer-text;
		&:hover{
			color: $text_dark_hover !important;
			text-decoration: none;
		}
	}
	&.uk-link-text {
		text-decoration: none;
		&.add_hover_effect{
			&:hover{
				color: $text_light;
				*{
					color: $text_light!important;
				}
			}
		}
	}
	&.small_tab_on_mobile{
		@media(max-width: $breakpoint-medium){
			@include padding(20px 50px);
		}
		@media(max-width: $breakpoint-small){
			@include padding(10px 10px);
		}
	}
	&.rounded_button{
		border-radius: 5px;
	}
	&.nav_expand{
		&::before{
			float: left;
		}
	}
}