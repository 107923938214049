/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 17.12.2018, 10:56:50
*/
////
/// @group g
////

///Extension to create styles for text to be placed inside the footer-region
///@require uikit and variables.scss
///@since v1.0.0
///@output Font color $text_dark, multiple font-sizes as well as subclasses '.pbi' and '.thend'
%footer-text{
	color: $text_dark !important;
	font-size: 16px;
	@media(max-width: $breakpoint-medium){
		font-size: .9rem;
	}
	&.pbi{
		font-size: 15px;
		@media(max-width: $breakpoint-medium){
		font-size: .8rem;
	}
	}
	&.thend{
		font-size: 12px;
	}
}

///Defines general button style
///@require variables.scss
///@since v1.0.0
///@output Button styles, see example at /restaurant
%button{
	border-radius: 3px;
	border-color: $gruen;
	background-color: transparent;
	&:hover,
	&:focus{
		background-color: $gruen;
		border-color: $gruen;
		span{
			color: #ffffff !important;
		}
	}
	&.full{
		background-color: $gruen;
		&:hover{
			background-color: $gruen_hover;
			border-color: $gruen_hover;
		}
	}
}
%inline-list{
	li{
		display: inline;
		&:before,
		&:after{
			display: unset;
		}
	}
	&.break-list-on-mobile{
		li{
			@media (max-width: $breakpoint-small){
				display: list-item;
			}
		}
	}
}