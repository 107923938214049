/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 19.12.2018, 13:47:57
    
*/

caption {
	@include padding(16px 12px);
    font-family: 'roboto_bold';
    color: #ffffff;
    text-transform: uppercase;
}