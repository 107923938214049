/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 25.01.2019, 16:12:29
    
*/

g{
	&.cls-1{
		fill: unset;
	}
}