/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 03.12.2018, 13:15:58
    
*/

h1{
	hyphens: auto;
	&.hero_on_small_screens{
		@media(max-width: $breakpoint-small){
			font-size: 3rem;
		}
	}
	&.smaller_headline{
		@media(min-width: $breakpoint-medium){
			font-size: 6rem !important;
		}
	}
	&.banner_subtitle_level_1{
		font-size: $heading-hero-font-size - 3;
		line-height: $heading-hero-line-height;
		@media (max-width: $breakpoint-medium){
			margin-bottom: 0px!important;
		}
		@media (min-width: $breakpoint-medium) {
			font-size: $heading-hero-font-size-m - 2;
			line-height: $heading-hero-line-height-m;
		}
		@media (min-width: $breakpoint-large) {
			font-size: $heading-hero-font-size-l - 4;
			line-height: $heading-hero-line-height-l;
		}
	}
}

h2 {
	&.insta{
		@media(max-width: $breakpoint-medium){
			font-size: 2rem;
		}
	}
}

h3{
	color: $text_dark;
	&.subheading_larger{
		font-size: 2rem;
		font-family: playfair_regular;
	}
}