/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 07.01.2019, 08:15:30
    
*/

pre{
	background: unset;
}