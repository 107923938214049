/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 11.12.2018, 16:27:13
    
*/

p{
	&.collage{
		font-family:'roboto_regular';
		font-size:16px;
		line-height: 2;
		opacity: .8;
	}
	&.cite_on_m{
		@media(max-width:$breakpoint-medium){
			position:absolute;
			left: $padding-padding-l;
		}
	}
	&.cite_padding{
		@media(min-width:$breakpoint-medium){
			padding-top: 1.5rem;
		}
		top: 2vh;
	}
}