/*
To change this license header, choose License Headers in Project Properties.
To change this template file, choose Tools | Templates
and open the template in the editor.
*/
/*
    Created on : 27.02.2019, 16:09:38
    
*/

svg{
	&.svg_dark{
		color: $svg_dark;
		g{
			fill: currentcolor;
		}
	}
}