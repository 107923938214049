/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 29.11.2018, 11:16:58
    
*/

ul {
	&.menu_list {
		line-height: 2;
		font-size: $global-large-font-size;
/*		@media screen and (max-width: $breakpoint-small) , screen and (max-height: 660px) {
			li{
				display: inline;
				&:before,
				&:after{
					display: unset;
				}
				font-size: $global-medium-font-size;
				&:not(:last-child){
					@include padding(null 10px null null);
					@include margin(null 5px null null);
					@include border-width(null 1px null null);
					@include border-style(null solid null null);
					@include border-color(null $orange null null);
				}
			}
		}*/
		&.sub{
			li{
				line-height: 2.5;
				font-size: $global-small-font-size;
				@media screen and (max-width: $breakpoint-small) , screen and (max-height: 660px) {
					line-height: 2.5;
				}
			}
		}
	}
	&.product_list {
		font-family: 'font_playfair';
		font-size: 1.4rem;
		line-height: 2;
		
	}
	&.uk-tab {
		li {
			a {
				&:hover {
						color: $text_dark;
					}
			}
			&.uk-active {
				a {
					color: $orange;
				}
			}
		}
	}
	&.inline-list{
		@extend %inline-list;
	}
	&.mini-margin-top{
		li{
			&:nth-child(n+2){
				margin-top: 5px!important;
			}
		}
	}
	&.thumb-nav{
		@media screen and (max-width: $breakpoint-medium){
			max-height:300px;
		}
		@media screen and (min-width: $breakpoint-medium){
			max-height:400px;
		}
	}
}