/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 03.12.2018, 14:16:39
    
*/

span{
	&.button_text_padding{
		padding-right: 10px;
	}
	&.footer_text{
		@extend %footer-text;
	}
	&.text_dark{
		font-family: 'roboto_regular';
		color: $text_dark;
	}
	&.banner_subtitle_level_2{
		font-family: 'playfair_regular';
		font-size: 2rem;
		@media (min-width: $breakpoint-medium) {
			font-size: 3rem;
			line-height: $heading-hero-line-height-m;
		}
		@media (min-width: $breakpoint-large) {
			font-size: 4rem;
			line-height: $heading-hero-line-height-l;
		}
	}
}