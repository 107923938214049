/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 05.12.2018, 11:47:50
    
*/

option{
	&:disabled{
		color: $global-muted-color!important;
	}
}