/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 11.12.2018, 14:39:07
    
*/

table{
	&.th_table{
		tbody{
			tr{
				&.border_color_overwrite_dark_bg{
					border-color: rgba(100,100,100,1)!important;
				}
				&.border_color_overwrite_light_bg{
					border-color: rgba(238,238,238,1)!important;
				}
				&:last-child{
					border-bottom-width: 1px;
					border-bottom-style: solid;
				}
			}
		}
	}
	&.footer_info_table{
		tbody{
			tr{
				td{
					&:first-child{
						width: 15px;
					}
				}
			}
		}
	}
}