/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 29.11.2018, 12:52:35
*/


//PLAYFAIR FONTS
@font-face {
  font-family: "playfair_regular";
  src: url("../fonts/playfair/regular/PlayfairDisplay-Regular.eot") format('eot');
  src: local("Playfair Display Regular"),
		url("../fonts/playfair/regular/PlayfairDisplay-Regular.woff2") format('woff2'),
		url("../fonts/playfair/regular/PlayfairDisplay-Regular.woff") format('woff'),
		url("../fonts/playfair/regular/PlayfairDisplay-Regular.otf") format('otf'),
		url("../fonts/playfair/regular/PlayfairDisplay-Regular.ttf") format('ttf'),
		url("../fonts/playfair/regular/PlayfairDisplay-Regular.svg") format('svg'),
		url("../fonts/playfair/regular/PlayfairDisplay-Regular.eot?#iefix") format('eot');
}
@font-face {
  font-family: "playfair_italic";
  src: url("../fonts/playfair/italic/PlayfairDisplay-Italic.eot") format('eot');
  src: local("Playfair Display Italic"),
		url("../fonts/playfair/italic/PlayfairDisplay-Italic.woff2") format('woff2'),
		url("../fonts/playfair/italic/PlayfairDisplay-Italic.woff") format('woff'),
		url("../fonts/playfair/italic/PlayfairDisplay-Italic.otf") format('otf'),
		url("../fonts/playfair/italic/PlayfairDisplay-Italic.ttf") format('ttf'),
		url("../fonts/playfair/italic/PlayfairDisplay-Italic.svg") format('svg'),
		url("../fonts/playfair/italic/PlayfairDisplay-Italic.eot?#iefix") format('eot');
}
@font-face {
  font-family: "playfair_bold";
  src: url("../fonts/playfair/bold/PlayfairDisplay-Bold.eot") format('eot');
  src: local("Playfair Display Bold"),
		url("../fonts/playfair/bold/PlayfairDisplay-Bold.woff2") format('woff2'),
		url("../fonts/playfair/bold/PlayfairDisplay-Bold.woff") format('woff'),
		url("../fonts/playfair/bold/PlayfairDisplay-Bold.otf") format('otf'),
		url("../fonts/playfair/bold/PlayfairDisplay-Bold.ttf") format('ttf'),
		url("../fonts/playfair/bold/PlayfairDisplay-Bold.svg") format('svg'),
		url("../fonts/playfair/bold/PlayfairDisplay-Bold.eot?#iefix") format('eot');
}
@font-face {
  font-family: "playfair_black_italic";
  src: url("../fonts/playfair/black_italic/PlayfairDisplay-BlackItalic.eot") format('eot');
  src: local("Playfair Display Black Italic"),
		url("../fonts/playfair/black_italic/PlayfairDisplay-BlackItalic.woff2") format('woff2'),
		url("../fonts/playfair/black_italic/PlayfairDisplay-BlackItalic.woff") format('woff'),
		url("../fonts/playfair/black_italic/PlayfairDisplay-BlackItalic.otf") format('otf'),
		url("../fonts/playfair/black_italic/PlayfairDisplay-BlackItalic.ttf") format('ttf'),
		url("../fonts/playfair/black_italic/PlayfairDisplay-BlackItalic.svg") format('svg'),
		url("../fonts/playfair/black_italic/PlayfairDisplay-BlackItalic.eot?#iefix") format('eot');
}
//ROBOTO FONTS
@font-face {
  font-family: "roboto_regular";
  src: url("../fonts/roboto/regular/Roboto-Regular.eot") format('eot');
  src: local("Roboto Regular"),
		url("../fonts/roboto/regular/Roboto-Regular.woff2") format('woff2'),
		url("../fonts/roboto/regular/Roboto-Regular.woff") format('woff'),
		url("../fonts/roboto/regular/Roboto-Regular.otf") format('otf'),
		url("../fonts/roboto/regular/Roboto-Regular.ttf") format('ttf'),
		url("../fonts/roboto/regular/Roboto-Regular.svg") format('svg'),
		url("../fonts/roboto/regular/Roboto-Regular.eot?#iefix") format('eot');
}
@font-face {
  font-family: "roboto_italic";
  src: url("../fonts/roboto/italic/Roboto-Italic.eot") format('eot');
  src: local("Roboto Italic"),
		url("../fonts/roboto/italic/Roboto-Italic.woff2") format('woff2'),
		url("../fonts/roboto/italic/Roboto-Italic.woff") format('woff'),
		url("../fonts/roboto/italic/Roboto-Italic.otf") format('otf'),
		url("../fonts/roboto/italic/Roboto-Italic.ttf") format('ttf'),
		url("../fonts/roboto/italic/Roboto-Italic.svg") format('svg'),
		url("../fonts/roboto/italic/Roboto-Italic.eot?#iefix") format('eot');
}
@font-face {
  font-family: "roboto_light";
  src: url("../fonts/roboto/light/Roboto-Light.eot") format('eot');
  src: local("Roboto Light"),
		url("../fonts/roboto/light/Roboto-Light.woff2") format('woff2'),
		url("../fonts/roboto/light/Roboto-Light.woff") format('woff'),
		url("../fonts/roboto/light/Roboto-Light.otf") format('otf'),
		url("../fonts/roboto/light/Roboto-Light.ttf") format('ttf'),
		url("../fonts/roboto/light/Roboto-Light.svg") format('svg'),
		url("../fonts/roboto/light/Roboto-Light.eot?#iefix") format('eot');
}
@font-face {
  font-family: "roboto_light_italic";
  src: url("../fonts/roboto/light_italic/Roboto-LightItalic.eot") format('eot');
  src: local("Roboto LightItalic"),
		url("../fonts/roboto/light_italic/Roboto-LightItalic.woff2") format('woff2'),
		url("../fonts/roboto/light_italic/Roboto-LightItalic.woff") format('woff'),
		url("../fonts/roboto/light_italic/Roboto-LightItalic.otf") format('otf'),
		url("../fonts/roboto/light_italic/Roboto-LightItalic.ttf") format('ttf'),
		url("../fonts/roboto/light_italic/Roboto-LightItalic.svg") format('svg'),
		url("../fonts/roboto/light_italic/Roboto-LightItalic.eot?#iefix") format('eot');
}
@font-face {
  font-family: "roboto_medium";
  src: url("../fonts/roboto/medium/Roboto-Medium.eot") format('eot');
  src: local("Roboto Medium"),
		url("../fonts/roboto/medium/Roboto-Medium.woff2") format('woff2'),
		url("../fonts/roboto/medium/Roboto-Medium.woff") format('woff'),
		url("../fonts/roboto/medium/Roboto-Medium.otf") format('otf'),
		url("../fonts/roboto/medium/Roboto-Medium.ttf") format('ttf'),
		url("../fonts/roboto/medium/Roboto-Medium.svg") format('svg'),
		url("../fonts/roboto/medium/Roboto-Medium.eot?#iefix") format('eot');
}
@font-face {
  font-family: "roboto_bold";
  src: url("../fonts/roboto/bold/Roboto-Bold.eot") format('eot');
  src: local("Roboto Bold"),
		url("../fonts/roboto/bold/Roboto-Bold.woff2") format('woff2'),
		url("../fonts/roboto/bold/Roboto-Bold.woff") format('woff'),
		url("../fonts/roboto/bold/Roboto-Bold.otf") format('otf'),
		url("../fonts/roboto/bold/Roboto-Bold.ttf") format('ttf'),
		url("../fonts/roboto/bold/Roboto-Bold.svg") format('svg'),
		url("../fonts/roboto/bold/Roboto-Bold.eot?#iefix") format('eot');
}
@font-face {
  font-family: "roboto_black";
  src: url("../fonts/roboto/black/Roboto-Black.eot") format('eot');
  src: local("Roboto Bold"),
		url("../fonts/roboto/black/Roboto-Black.woff2") format('woff2'),
		url("../fonts/roboto/black/Roboto-Black.woff") format('woff'),
		url("../fonts/roboto/black/Roboto-Black.otf") format('otf'),
		url("../fonts/roboto/black/Roboto-Black.ttf") format('ttf'),
		url("../fonts/roboto/black/Roboto-Black.svg") format('svg'),
		url("../fonts/roboto/black/Roboto-Black.eot?#iefix") format('eot');
}

@font-face {
  font-family: "hw_quentin";
  src: url("../fonts/quentin/Quentin.eot") format('eot');
  src: local("Quentin"),
		url("../fonts/quentin/Quentin.woff2") format('woff2'),
		url("../fonts/quentin/Quentin.woff") format('woff'),
		url("../fonts/quentin/Quentin.otf") format('otf'),
		url("../fonts/quentin/Quentin.ttf") format('ttf'),
		url("../fonts/quentin/Quentin.svg") format('svg'),
		url("../fonts/quentin/Quentin.eot?#iefix") format('eot');
}

@font-face {
  font-family: "hw_blackjack";
  src: url("../fonts/blackjack/BlackJack.eot") format('eot');
  src: local("BlackJack"),
		url("../fonts/blackjack/BlackJack.woff2") format('woff2'),
		url("../fonts/blackjack/BlackJack.woff") format('woff'),
		url("../fonts/blackjack/BlackJack.otf") format('otf'),
		url("../fonts/blackjack/BlackJack.ttf") format('ttf'),
		url("../fonts/blackjack/BlackJack.svg") format('svg'),
		url("../fonts/blackjack/BlackJack.eot?#iefix") format('eot');
}