/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 07.12.2018, 13:43:33
    
*/

img {
	&.portrait {
		border-radius: 50%;
	}
	&.insta_mobile{
		@media(max-width: $breakpoint-medium){
			/*max-height: 200px;*/
			object-fit: cover;
		}
	}
}