/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 29.11.2018, 09:20:51

*/

div {
    //NAV
    &.gradient {
        /*		@include filter-gradient(rgba(0,0,0,0.65), rgba(0,0,0,0), vertical);
		$experimental-support-for-svg: true;
		@include background-image(linear-gradient(top, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0.25) 50%, rgba(0,0,0,0.2) 80%, rgba(0,0,0,0.1) 90%, rgba(0,0,0,0) 100%));*/
        div {
            nav {
                div {
                    &:not(:nth-child(2)) {
                        z-index: 2;
                    }
                    @media (max-width: $breakpoint-medium) {
                        &:nth-child(2) {
                            z-index: 0;
                        }
                    }
                }
            }
        }
    }
    &.bottom_gradient {
        background: linear-gradient(
            0deg,
            rgba(0, 11, 23, 0.695098107602416) 0%,
            rgba(0, 11, 23, 0.35056029247636555) 30%,
            rgba(0, 11, 23, 0.20210090872286413) 60%,
            rgba(0, 11, 23, 0.10126057258841037) 80%,
            rgba(0, 11, 23, 0) 100%
        );
    }
    &.nav_force_top_align {
        @media (max-width: $breakpoint-medium) {
            align-items: flex-start;
            a {
                align-items: flex-start;
            }
        }
    }
    //GENERAL MODAL SEETINGS
    &.uk-modal-dialog {
        &.uk-margin-auto-vertical {
            @include border-top-radius(3px);
            @include border-bottom-radius(3px);
            & .uk-modal-header {
                @include border-top-radius(3px);
            }
            & .uk-modal-footer {
                @include border-bottom-radius(3px);
            }
        }
    }

    //MENU MODAL
    &#menu {
        z-index: 1;
        & .uk-modal-dialog {
            background-color: rgba(0, 0, 0, 0.8);
            button {
                &.uk-icon {
                    background: unset;
                }
            }
        }
    }

    //SPECIAL STUFF
    &.center_text_on_mobile {
        @media (max-width: $breakpoint-medium) {
            text-align: center;
        }
    }
    $pull-list: 80, 140;
    @each $pull in $pull-list {
        &.pull_up_#{$pull} {
            @media (min-width: $breakpoint-medium) {
                margin-top: -#{$pull}px !important;
            }
        }
    }
    &.pull_up {
        @media (min-width: $breakpoint-medium) {
            margin-top: -140px !important;
        }
    }
    &.remove-padding-left-on-desktop {
        @media (min-width: 1340px) {
            @include padding(null null null 0px);
        }
    }
    &.hamburger {
        outline: none;
        height: 20px;
    }
    &.logo_home {
        z-index: 0;
        @media screen and (min-height: 600px) {
            top: 150% !important;
            @media (max-width: 1200px) {
                top: 250% !important;
            }
        }
    }
    &.menu_distance_at_home {
        @media screen and (min-height: 600px) and (max-height: 800px) {
            height: 130px;
        }
        @media screen and (min-height: 800px) {
            height: 180px;
        }
    }
    &.small_margin_on_mobile {
        @media (max-width: $breakpoint-medium) {
            margin-top: $global-small-margin !important;
        }
    }
}
