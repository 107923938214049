/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 09.01.2019, 13:28:58
*/

///Overwrites for inherited, non-customizable styles from node_modules

.hamburger{
	display: flex!important
}

div{
	&.uk-grid-divider{
		& .uk-first-column{
			&:before{
				border-width: 0px!important;
			}
		}
	}
}

//prevent container max width in banner being overwritten by uk-position
.uk-container-xsmall {
	max-width: $container-xsmall-max-width !important;
}

.uk-container-small {
	max-width: $container-small-max-width !important;
}

.uk-container-large {
	max-width: $container-large-max-width !important;
}

.uk-container-xlarge {
	max-width: $container-xlarge-max-width !important;
}

.uk-container-expand {
	max-width: none !important;
}