/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 29.11.2018, 14:55:30
    
*/

html{
	background-color: $dark;
}