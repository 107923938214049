/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 29.11.2018, 13:51:57
*/

#{$all-buttons} {
    @extend %button;
}

.smaller_text {
    font-size: 0.875rem;
    @media (max-width: $breakpoint-small) {
        font-size: 0.7rem;
		&.normalize_on_mobile {
			font-size: 1rem;
		}
    }
}
.big_text {
    font-size: 1.2rem;
    @media (max-width: $breakpoint-medium) {
        font-size: 1.1rem;
    }
}
.bigger_text {
    font-size: 1.6rem;
    @media (max-width: $breakpoint-medium) {
        font-size: 1.2rem;
    }
}
.maxi_text {
    font-size: 2rem;
    @media (max-width: $breakpoint-medium) {
        font-size: 1.3rem;
    }
}
.max_text {
    font-size: 2.5rem;
    @media (max-width: $breakpoint-medium) {
        font-size: 1.5rem;
    }
}
.responsive_padding {
    @media (max-width: $breakpoint-small) {
        @include padding(15px);
    }
}
.responsive_padding_large {
    @include padding(30px);
    @media (min-width: $breakpoint-large) {
        @include padding(40px);
    }
    @media (max-width: $breakpoint-small) {
        @include padding(unset);
    }
}

.footer_no-right-padding-on-mobile {
    @media (max-width: $breakpoint-medium) {
        padding-right: unset;
        padding-left: unset !important;
    }
}
.bg_white {
    background-color: #ffffff;
}
.bg_mldl {
    background-color: #e13701;
}
.th_button {
    @extend %button;
}
.hide_on_mini {
    @media screen and (max-height: 450px) {
        display: none;
    }
}

$height-list: 10, 20, 30, 40, 60, 70, 80, 100, 140, 160;
@each $height in $height-list {
    .height-#{$height} {
        height: #{$height}px;
    }
}

$opacity-list: 8;
@each $opacity in $opacity-list {
    .opacity-#{$opacity} {
        opacity: #{'.'}$opacity;
    }
}

//TEXT SETTINGS
.reset-fontsize {
    font-size: unset;
}
.text-color-orange {
    color: $orange;
}
.text-color-green {
    color: $gruen;
}
.text-color-lighter {
    color: $text_light !important;
    opacity: 0.6 !important;
}
.text-color-light {
    color: $text_light !important;
}
.text-color-dark {
    color: $text_dark;
    input,
    caption,
    label,
    option,
    p,
    select,
    span,
    textarea {
        color: $text_dark !important;
        &:focus {
            color: $text_dark;
        }
    }
}
.text-color-dark-alt {
    color: $text-dark-alt;
}

.text-color-dark-force {
    color: $text_dark !important;
}

.text-color-dark-force-hover {
    &:hover {
        color: $text_dark_hover !important;
    }
}

.text-color-dark-extra {
    color: $svg_dark !important;
}

.font-playfair-regular {
    font-family: 'playfair_regular', serif;
}
.font-playfair-italic {
    font-family: 'playfair_italic', serif;
}
.font-playfair-bold {
    font-family: 'playfair_bold', serif;
}
.font-playfair-blackitalic {
    font-family: 'playfair_black_italic', serif;
}

.font-roboto-regular {
    font-family: 'roboto_regular', sans-serif;
}

.font-roboto-italic {
    font-family: 'roboto_italic', sans-serif;
}

.font-roboto-light {
    font-family: 'roboto_light', sans-serif;
}

.font-roboto-lightitalic {
    font-family: 'roboto_light_italic', sans-serif;
}

.font-roboto-medium {
    font-family: 'roboto_medium', sans-serif;
}

.font-roboto-bold {
    font-family: 'roboto_bold', sans-serif;
}

.font-hw-quentin {
    font-family: 'hw_quentin', sans-serif;
}

.font-hw-blackjack {
    font-family: 'hw_blackjack', sans-serif;
}
