/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 11.12.2018, 15:29:38
    
*/

path{
	&.cls-1{
		fill: unset;
		stroke: unset;
	}
}