/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 17.12.2018, 10:43:53
*/

//SET GENERAL CUSTOM COLOURS
$orange: #df691b;
// $orange_hover: #ae6402;
$orange_hover : #ea8c4f;
$dark: #0b1c28;

$gruen: #3CD250;
$gruen_hover: #73df81;

$blau: #001937;
$blau_hover: #001f45;

$svg_dark: #1c2c37;
$text_light: #ffffff;
$text_light_hover: #cccccc;
$text_dark: #747f86;
$text_dark_hover: #959ea3;
$text_dark_alt: #ccced7;
$navbar-toggle-color: $text_light;
$navbar-toggle-hover-color: $text_light_hover;
$grid-divider-border: $gruen;
/*$heading-bullet-border: #747f86;*/
$list-bullet-icon-color: $text_dark;

$nav-default-item-color: $text_light;
$nav-default-item-hover-color: $text_light_hover;
$global-font-family: "roboto_regular", serif;
$global-primary-background: $gruen;
$global-color: $text_light;
$global-link-color: $text_light_hover;
$inverse-global-color: $text_dark;
$global-emphasis-color: $text_light;
$navbar-nav-item-color: $text_light;
$navbar-nav-item-hover-color: $text_light_hover;
$iconnav-item-color: $text_light;
$iconnav-item-hover-color: $text_light_hover;
$heading-hero-font-size-l: 11rem;
$section-secondary-background: #ffffff;
$form-select-option-color: $text_dark;
$container-large-max-width: 1200px;
$global-border: rgb(238,238,238);
$tab-border-width: 0px;
$tab-item-padding-horizontal: 100px;
$tab-item-padding-vertical: 40px;
$countdown-number-font-size-m: 4rem;
$global-large-gutter: 100px;

/*$global-font-size: 24px;*/

$hamburger-padding-x			:	0px;
$hamburger-padding-y			:	0px;
$hamburger-layer-width			:	20px;
$hamburger-layer-height			:	2px;
$hamburger-layer-spacing		:	4px;
$hamburger-layer-color			:	#ffffff;
$hamburger-hover-opacity		:	1;

$height-small-height: 50px;
$height-medium-height: 150px;

$graceful-usage-threshold		:	0.1;
$gradient-support-threshold		:	$graceful-usage-threshold;
$svg-gradient-shim-threshold	:	$graceful-usage-threshold;
$owg-threshold					:	$graceful-usage-threshold;
$default-capability-options		:	();
$current-browser-versions		:	();
$current-prefix					:	null;
$prefix-context					:	null;
$debug-browser-support			:	false;
$browsers-supporting-svg-but-not-gradients	:	(ie: "9", opera: "9.5-9.6");
$browsers-supporting-old-webkit-gradients	:	(android: ("2.1", "3"));

$internal-form-select-image : "data:image/svg+xml;charset=UTF-8,%3Csvg%20width%3D%2224%22%20height%3D%2216%22%20viewBox%3D%220%200%2024%2016%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%201%209%206%2015%206%22%20%2F%3E%0A%20%20%20%20%3Cpolygon%20fill%3D%22%23666%22%20points%3D%2212%2013%209%208%2015%208%22%20%2F%3E%0A%3C%2Fsvg%3E%0A";