/*
This file is a part of proprietary software.
Its usage or modification is prohibited in any environment not associated with the Tannenhaus Hotel & Restaurant.
*/
/*
    Created on : 05.12.2018, 12:03:14
    
*/

input{
	&[type=date]{
		&::-webkit-inner-spin-button{
			display: none;
			appearance: none;
		}
	}
}